
import { defineComponent, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import AddMemberModal from "@/components/modals/forms/AddMemberModal.vue";
import MemberDetailAccess from "@/components/modals/general/MemberDetailAccess.vue";
import AddMultipleDatabase from "@/components/modals/general/AddMultipleDb.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { IMember, serverListInterface } from "@/models/member/IMember";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ElMessage, ElMessageBox } from "element-plus";

interface IMemberType {
  id: number;
  name: string;
}

export default defineComponent({
  name: "member-listing",
  emits: ["membersDeleted"],
  components: {
    Datatable,
    AddMemberModal,
    MemberDetailAccess,
    AddMultipleDatabase,
  },
  data() {
    return {
      checkedMembers: [],
      headers: [
        {
          key: "checkbox",
          sortable: false,
        },
        {
          name: "Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Email",
          key: "email",
          sortable: true,
        },
        {
          name: "Username",
          key: "username",
          sortable: true,
        },
        {
          name: "Sonar Account",
          key: "sonaraccount",
          sortable: true,
        },
        {
          name: "Add to Server",
          key: "addtoserver",
          sortable: false,
        },
      ],
      search: "",
      members: ref<Array<IMember>>([]),
      initialData: ref<Array<IMember>>([]),
      listServer: ref<serverListInterface[]>([]),
      selectedMember: ref<IMember>({
        member_id: undefined,
        name: "",
        email: "",
        username: "",
      }),
      memberType: ref<IMemberType[]>([]),
      userType: ref<number>(0),
    };
  },
  methods: {
    deleteSelectedMembers() {
      this.checkedMembers.forEach((member) => {
        this.deleteMember(member);
      });
      this.checkedMembers.length = 0;
      this.$emit("membersDeleted");
    },
    deleteMember(id) {
      for (let i = 0; i < this.members.length; i++) {
        if (this.members[i].member_id === id) {
          ApiService.vueInstance.axios
            .post("database/v1/member/" + id + "/delete")
            .catch((err) => {
              Swal.fire({
                title: err.response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
          this.members.splice(i, 1);
        }
      }
    },
    async getMemberList(memberType = 0) {
      try {
        const memberList = await ApiService.vueInstance.axios.get(
          `database/v1/member/list?member_type=${memberType}`
        );
        this.members.splice(0, this.members.length, ...memberList.data.data);
        this.initialData.splice(
          0,
          this.initialData.length,
          ...memberList.data.data
        );
      } catch (error) {
        if (error instanceof Error) {
          // ✅ TypeScript knows err is Error
          ElMessage.error(error.message);
        } else {
          ElMessage.error("Unexpected error");
        }
      }
    },
    async getMemberType() {
      try {
        const memberTypeList = await ApiService.vueInstance.axios(
          "database/v1/member/types"
        );
        this.memberType.splice(
          0,
          this.memberType.length,
          ...memberTypeList.data.data
        );
      } catch (error) {
        if (error instanceof Error) {
          // ✅ TypeScript knows err is Error
          ElMessage.error(error.message);
        } else {
          ElMessage.error("Unexpected error");
        }
      }
    },
    searchMember() {
      this.members.splice(0, this.members.length, ...this.initialData);
      if (this.search.length > 2) {
        const filteredMember = this.members.filter((el) => {
          return this.keyMatches(el, this.search);
        });
        this.members.splice(0, this.members.length, ...filteredMember);
      }
    },
    keyMatches(obj, value): boolean {
      for (let key in obj) {
        if (
          !Number.isInteger(obj[key]) &&
          !(typeof obj[key] === "object") &&
          !(typeof obj[key] === "boolean")
        ) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    },
    addToMultiDB(userData) {
      this.selectedMember = userData;
    },
    async openList(userData) {
      try {
        const memberDetail = await ApiService.vueInstance.axios.get(
          `database/v2/member/${userData.member_id}/detail`
        );
        this.selectedMember = memberDetail.data.data;
      } catch (error) {
        //perform error action
        if (error instanceof Error) {
          // ✅ TypeScript knows err is Error
          ElMessage.error(error.message);
        } else {
          ElMessage.error("Unexpected error");
        }
      }
    },
    async getServerList() {
      try {
        const serverList = await ApiService.vueInstance.axios.get(
          `database/v1/server/list`
        );
        this.listServer = serverList.data.data;
      } catch (error) {
        //perform error action
        if (error instanceof Error) {
          // ✅ TypeScript knows err is Error
          ElMessage.error(error.message);
        } else {
          ElMessage.error("Unexpected error");
        }
      }
    },

    //Sonarcube method
    addMember({ member_id, username }) {
      ElMessageBox.confirm(
        `Create ${username} as user to sonarcube. continue ?`,
        "Deactive user",
        {
          confirmButtonText: "Create",
          cancelButtonText: "Cancel",
          type: "info",
        }
      )
        .then(() => {
          return ApiService.vueInstance.axios.post(`sonar/v1/user`, {
            member_id,
          });
        })
        .then(() => {
          this.getMemberList(this.userType);
          ElMessage({
            type: "success",
            message: `Success create ${username} to sonarcube`,
          });
        })
        .catch(() => {
          ElMessage({
            type: "error",
            message: `Error when creating ${username} to sonarcube`,
          });
        });
    },
    deactiveMember(memberData) {
      ElMessageBox.confirm(
        `User ${memberData.username} will be deactived. continue ?`,
        "Deactive user",
        {
          confirmButtonText: "Deactive",
          cancelButtonText: "Cancel",
          type: "info",
        }
      )
        .then(() => {
          return ApiService.vueInstance.axios.post(
            `sonar/v1/user/${memberData.member_id}/deactivate`
          );
        })
        .then(() => {
          this.getMemberList(this.userType);
          ElMessage({
            type: "success",
            message: `Success deactive user ${memberData.username}`,
          });
        })
        .catch((error) => {
          if (error.response.data.message.includes("exists")) {
            ElMessage({
              type: "error",
              message: error.response.data.message,
            });
          } else {
            ElMessage({
              type: "error",
              message: `Error when deactive user ${memberData.username}`,
            });
          }
        });
    },
    resetMemberPassword(memberData) {
      ElMessageBox.confirm(
        "Pasword will be reset. continue ?",
        "Reset user password",
        {
          confirmButtonText: "Reset",
          cancelButtonText: "Cancel",
          type: "info",
        }
      )
        .then(() => {
          return ApiService.vueInstance.axios.post(
            `sonar/v1/user/${memberData.member_id}/reset`
          );
        })
        .then(() => {
          ElMessage({
            type: "success",
            message: "Success reset user password",
          });
        })
        .catch(() => {
          ElMessage({
            type: "error",
            message: "Error when reset password",
          });
        });
    },
  },
  watch: {
    userType(memberType: number) {
      this.getMemberList(memberType);
    },
  },
  mounted() {
    this.getMemberList(0);
    this.getServerList();
    this.getMemberType();
    setCurrentPageBreadcrumbs("Member List", []);
  },
});
