import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e2d67c60"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal fade",
  id: "member_detail_access",
  tabindex: "1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-850px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  class: "modal-header",
  id: "kt_modal_add_server_member_header"
}
const _hoisted_5 = { class: "fw-bolder header-title" }
const _hoisted_6 = {
  id: "kt_modal_add_server_member_close",
  "data-bs-dismiss": "modal",
  class: "btn btn-icon btn-sm btn-active-icon-primary"
}
const _hoisted_7 = { class: "svg-icon svg-icon-1" }
const _hoisted_8 = { class: "paragraf-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(this.$props.memberData.name) + " detail data ", 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createVNode(_component_el_descriptions, { class: "description-wrapper" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_descriptions_item, { label: "Id" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(this.$props.memberData.member_id), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_descriptions_item, { label: "Email" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(this.$props.memberData.email), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_descriptions_item, { label: "" }),
            _createVNode(_component_el_descriptions_item, { label: "Username" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(this.$props.memberData.username), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_descriptions_item, { label: "Name" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(this.$props.memberData.name), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_table, {
          class: "table-wrapper",
          data: this.$props.memberData.server_list,
          border: "",
          "table-layout": "auto",
          "default-expand-all": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              prop: "name",
              label: "Name"
            }),
            _createVNode(_component_el_table_column, {
              prop: "address",
              label: "Address"
            }),
            _createVNode(_component_el_table_column, { label: "Access Grant" }, {
              default: _withCtx((props) => [
                _createElementVNode("p", _hoisted_8, _toDisplayString(props.row.database_member_access_list[0].access_grant), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, { label: "Wildcard" }, {
              default: _withCtx((props) => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.database_member_access_list, (access) => {
                  return (_openBlock(), _createElementBlock("p", {
                    class: "paragraf-item",
                    key: access
                  }, _toDisplayString(access.wildcard), 1))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data"])
      ])
    ])
  ]))
}