
import { defineComponent } from "vue";
import { IMember } from "@/models/member/IMember";

export default defineComponent({
  name: "add-member-modal",
  props: {
    memberData: Object as () => IMember,
  },
  components: {},
  data() {
    return {};
  },
});
