
import { defineComponent, ref } from "vue";
import { serverListInterface } from "@/models/member/IMember";
import { hideModal } from "@/core/helpers/dom";
import ApiService from "@/core/services/ApiService";
import {
  ServerMemberAccessGroup,
  ServerMemberAccess,
} from "@/constants/ServerMemberAccess";
import { IMember } from "@/models/member/IMember";
import { ElMessageBox, ElMessage } from "element-plus";

type Nullable<T> = T | null;
interface PayloadItem {
  server_id?: Nullable<number>;
  grant: string;
  wildcard: string[];
  server_name?: string;
}

export default defineComponent({
  name: "add-multiple-db-modal",
  props: {
    listServer: Object as () => serverListInterface[],
    selectedUser: Object as () => IMember,
  },
  components: {},
  data() {
    return {
      modalRef: "addMultipleDatabaseModal",
      payloads: ref<PayloadItem[]>([]),
      form: ref<PayloadItem>({
        server_id: null,
        grant: "",
        wildcard: [],
        server_name: "",
      }),
      eachWildcard: ref<string>(""),
      wildcardItems: ref<string[]>([]),
      accessRadio: ref<string>("Pre Defined"),

      user: this.selectedUser,

      accessGroup: ServerMemberAccessGroup,
      accessList: ServerMemberAccess,
      checkedAccess: ref<string>(""),
      selectedAccess: ref<string[]>([]),
      activeCollapse: ref<string>(""),
    };
  },
  mounted() {
    let initialList: string[] = ["*"];
    this.wildcardItems.splice(0, initialList.length, ...initialList);
  },
  methods: {
    async onSubmit() {
      try {
        if (!this.selectedUser?.member_id)
          throw new Error("User doesn't exist");

        let payloadSubmit = this.payloads;
        if (this.payloads.length === 0) {
          payloadSubmit = this.onAddNewServer(false);
        }

        const result = await ApiService.vueInstance.axios.post(
          `database/v1/member/${this.selectedUser?.member_id}/batch/add-server`,
          { payloads: payloadSubmit }
        );

        if (result.data.data.successful_servers.length > 0) {
          ElMessageBox.alert(
            result.data.data.successful_servers.join(", "),
            "Success add to following server",
            {
              confirmButtonText: "OK",
            }
          );
        }

        this.resetPayloadInput();
        this.payloads = [];
        hideModal(this.$refs[this.modalRef] as HTMLElement);
      } catch (error) {
        //Perform errror action
        if (error instanceof Error) {
          // ✅ TypeScript knows err is Error
          ElMessage.error(error.message);
        } else {
          ElMessage.error("Unexpected error");
        }
      }
    },
    onAddNewServer(messageFlag: boolean) {
      let grant = this.selectedAccess.join(", ");
      const server_name = this.listServer?.filter(
        (el) => el.server_id === this.form.server_id
      )[0];
      if (this.accessRadio === "Pre Defined") {
        const selectedGrant = this.accessGroup.filter((el) => {
          return el.type === this.checkedAccess;
        });
        grant = selectedGrant[0].access.join(", ");
      }
      const payload = {
        server_id: this.form.server_id,
        grant,
        wildcard: this.form.wildcard,
        server_name: server_name?.name,
      };
      this.payloads.push(payload);
      if (messageFlag) {
        this.resetPayloadInput();
        ElMessage({
          message: "Success stack server, please add another server",
          type: "success",
        });
      }
      return [payload];
    },
    resetPayloadInput() {
      this.form = {
        server_id: null,
        grant: "",
        wildcard: [],
      };
      this.eachWildcard = "";
      this.accessRadio = "Pre Defined";
      this.checkedAccess = "";
      this.selectedAccess = [];
    },
    handleWildcardSelect(value: string) {
      if (typeof value === "string" && !this.form.wildcard.includes(value)) {
        this.form.wildcard.push(value);
      }
      this.eachWildcard = "";
    },
    options() {
      return this.wildcardItems.map((el) => {
        return {
          value: el,
          label: el,
        };
      });
    },
    optionsAccess() {
      return this.accessList.map((el) => {
        return {
          value: el,
          label: el,
        };
      });
    },
    async handleChange() {
      try {
        // NOTE: use this when data is success fetched in server
        // const wildcardListRaw = await ApiService.vueInstance.axios.get(
        //   `database/v1/server/${this.form.server_id}/wildcard/list`
        // );
        // const processedWildcard = wildcardListRaw.data.data.map((el) => {
        //   el;
        // });
        // this.wildcardItems = processedWildcard;

        this.wildcardItems = ["*", "beoffice", "beoffice_service"];
      } catch (error) {
        // perform error action
        if (error instanceof Error) {
          // ✅ TypeScript knows err is Error
          ElMessage.error(error.message);
        } else {
          ElMessage.error("Unexpected error");
        }
      }
    },
  },
  computed: {
    wildcardlist: function () {
      if (this.form.server_id) {
        return ["*"];
      }
      return [];
    },
    isNewServerStackPossible: function () {
      return (
        this.form.server_id !== null &&
        (this.checkedAccess !== "" || this.selectedAccess.length !== 0) &&
        this.form.wildcard.length !== 0
      );
    },
    sumbitDisable: function () {
      let result = true;
      if (
        this.payloads.length !== 0 ||
        (this.form.server_id !== null &&
          (this.checkedAccess !== "" || this.selectedAccess.length !== 0) &&
          this.form.wildcard.length !== 0)
      ) {
        result = false;
      }
      return result;
    },
  },
});
