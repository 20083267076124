
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { ElForm } from "element-plus";
import { IMember } from "@/models/member/IMember";
import { CloseBold } from "@element-plus/icons-vue";

interface IMemberType {
  id: number;
  name: string;
}

export default defineComponent({
  name: "add-member-modal",
  components: {
    CloseBold,
  },
  props: {
    memberType: Object as () => IMemberType[],
  },
  data() {
    return {
      addMemberModalRef: "addMemberModalRef",
      formData: ref<IMember>({
        name: "",
        email: "",
      }),
      formRef: "addServer",
      loading: ref<boolean>(false),
      rules: {
        name: [
          {
            required: true,
            message: "Member name is required",
            trigger: ["submit", "change"],
          },
        ],
        email: [
          {
            required: true,
            message: "Member email is required",
            trigger: ["change", "submit"],
          },
        ],
      },
      selectedMemberType: ref<number | null>(null),
      emailCcs: ref<string[]>([]),
      emailCcInput: ref<string>(""),
      addAsMember: ref<boolean>(false),
    };
  },
  methods: {
    selectedMemberOption() {
      return this.memberType?.map((el) => {
        return {
          value: el.id,
          label: el.name,
        };
      });
    },
    addNewMember(newServer: IMember) {
      ApiService.vueInstance.axios
        .post("database/v2/member/add", newServer)
        .then(() => {
          hideModal(this.$refs[this.addMemberModalRef] as HTMLElement);
          this.loading = false;
          this.resetData();
          Swal.fire({
            title: "Success add a member",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        })
        .then(() => {
          this.loading = false;
          this.$emit("addedNewMember");
        })
        .catch((err) => {
          this.loading = false;
          Swal.fire({
            title: err.response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        });
    },
    submit() {
      if (!this.$refs[this.formRef]) {
        return;
      }

      (this.$refs[this.formRef] as typeof ElForm).validate((valid) => {
        if (valid) {
          this.loading = true;
          const email_ccs = [...this.emailCcs, this.emailCcInput]
            .filter((email) => email.includes("@"))
            .map((email) => email.trim());
          const input = {
            name: this.formData.name.trim(),
            email: this.formData.email.trim(),
            member_type: this.selectedMemberType,
            email_ccs,
            email_exists: this.addAsMember,
          };
          this.addNewMember(input);
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    },
    resetData() {
      this.formData = {
        name: "",
        email: "",
      };
      this.selectedMemberType = null;
      this.emailCcs = [];
      this.emailCcInput = "";
      this.addAsMember = false;
    },
    removeCc(position) {
      this.emailCcs.splice(position, 1);
    },
    addCc() {
      if (!this.emailCcInput.includes("@")) {
        this.emailCcInput = "";
        return false;
      }

      const addItem = [...this.emailCcs, this.emailCcInput.trim()];
      this.emailCcs.splice(0, this.emailCcs.length, ...addItem);
      this.emailCcInput = "";
    },
  },
  setup() {
    return {};
  },
});
